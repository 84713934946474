import { FC, SyntheticEvent } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCurrentProjectId } from 'src/features/admin/hooks/useCurrentProjectId';
import { FetchErrorResult } from 'src/ui/Result';
import ProjectUsers from 'src/features/admin/components/ProjectUsers';
import { AdminItemType, AdminItemConfigurationPageTab } from 'src/features/admin/types';
import { RouteBuilder } from 'src/router';
import Actions from 'src/features/admin/components/Actions';
import { useCurrentProject } from 'src/features/admin/hooks/useCurrentProject';
import { AdminItemConfigurationPageTabs } from 'src/features/admin/constants';
import ProjectGroups from '../../../features/admin/components/Groups';

export const ProjectConfigurationPage: FC<{ selectedTab: AdminItemConfigurationPageTab }> = ({ selectedTab }) => {
  const navigate = useNavigate();
  const projectId = useCurrentProjectId();

  const { data: currentProject } = useCurrentProject();

  const onTabSelect = (event: SyntheticEvent, newTab: AdminItemConfigurationPageTab): void =>
    navigate(`/${RouteBuilder.adminView(AdminItemType.Project, Number(projectId), newTab)}`);

  if (!projectId) return <FetchErrorResult />;

  return (
    <Box gap={4} display="flex" flexDirection="column">
      <Tabs value={selectedTab} onChange={onTabSelect} variant="fullWidth">
        <Tab data-testid="groups-tab" label="Groups" value={AdminItemConfigurationPageTabs.Groups} />
        <Tab data-testid="users-tab" label="Users" value={AdminItemConfigurationPageTabs.Users} />
        <Tab data-testid="actions-tab" label="Actions" value={AdminItemConfigurationPageTabs.Actions} />
      </Tabs>
      <Box hidden={selectedTab !== AdminItemConfigurationPageTabs.Groups}>
        <ProjectGroups itemType={AdminItemType.Project} itemId={projectId} />
      </Box>
      <Box hidden={selectedTab !== AdminItemConfigurationPageTabs.Users}>
        <ProjectUsers projectId={projectId} />
      </Box>
      <Box hidden={selectedTab !== AdminItemConfigurationPageTabs.Actions}>
        <Actions productId={Number(currentProject?.productId)} itemType={AdminItemType.Project} />
      </Box>
    </Box>
  );
};
