import { FC } from 'react';

import pages from '../headerLinksList';
import RouterNavLink from './RouterNavLink';

const StdHeaderLinks: FC = () => {
  return (
    <>
      {pages.map((page) => (
        <RouterNavLink key={page.linkName} to={page.linkUrl} data-gtm-header-link={page.linkName}>
          {page.linkName}
        </RouterNavLink>
      ))}
    </>
  );
};

export default StdHeaderLinks;
