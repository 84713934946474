/**
 * EnvVars to configs mapping.
 *
 * We consider that all env vars are strings.
 *
 * Because when an inexistent EnvVar is referenced,
 * it will be `{}.INEXISTENT_VAR` instead of `undefined` in the built code,
 * so we always use `String(... ?? '')` to convert env vars to string,
 */

// please keep env vars in alphabetical order

// Identity API URL.
export const API_BASE_URL = String(import.meta.env.VITE_API_BASE_URL ?? '');
// URL for the background image on the login/logout pages.
export const AUTH_BACKGROUND_IMAGE_URL = String(import.meta.env.VITE_AUTH_BACKGROUND_IMAGE_URL ?? '');
// Chameleon API key.
export const CHAMELEON_API_KEY = String(import.meta.env.VITE_CHAMELEON_API_KEY ?? '');
// OAuth2 client ID.
export const CLIENT_ID = String(import.meta.env.VITE_CLIENT_ID ?? '');
// The subpath to the static CMS data in the `public/cms/*` folder.
export const CMS_PATH = String(import.meta.env.VITE_CMS_PATH ?? '');
// URL For the default project tile image.
export const DEFAULT_TILE_IMAGE = String(import.meta.env.VITE_DEFAULT_TILE_IMAGE ?? '');
// Google tag manager ID.
export const GTM_ID = String(import.meta.env.VITE_GTM_ID ?? '');
// MIA API URL for the identity frontend privacy policy.
export const PRIVACY_POLICY_URL = String(import.meta.env.VITE_PRIVACY_POLICY_URL ?? '');
// Sentry DSN.
export const SENTRY_DSN = String(import.meta.env.VITE_SENTRY_DSN ?? '');
// MIA API URL for the identity frontend terms and conditions
export const TERMS_URL = String(import.meta.env.VITE_TERMS_URL ?? '');
// please keep env vars in alphabetical order
