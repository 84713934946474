import { TextField } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import pluralize from 'pluralize';
import { ProjectGroupBaseDTO } from '@h2know-how/identity-sdk';

export const getDefaultInviteMessage = (
  selectedGroupIds: string[],
  groups?: ProjectGroupBaseDTO[],
  projectName?: string,
  userName?: string
): string => {
  const displayGroups = groups?.filter((group) => selectedGroupIds.includes(group.groupId)).map((group) => group.name);
  return (
    `Hello,\n` +
    `\n` +
    `I would like to invite you to the${projectName ? ` "${projectName}"` : ''} project on our Moata platform\n` +
    `\n` +
    `You are a member of the following ${pluralize('group', selectedGroupIds.length)}:\n` +
    `${displayGroups?.join(', ')}\n` +
    `\n` +
    `Best regards,\n${userName}`
  );
};

interface InvitationMessageStepProps {
  disabled?: boolean;
  value?: string;
  error?: string;
  onError: (error?: string) => void;
  onChange: (text: string) => void;
}

export const InvitationMessageStep: FC<InvitationMessageStepProps> = ({
  disabled = false,
  error,
  onError,
  value = '',
  onChange,
}) => {
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onChange(event.target.value);
    onError(undefined);
  };

  return (
    <TextField
      disabled={disabled}
      label="Invitation Message"
      multiline
      rows={9}
      fullWidth
      value={value}
      onChange={handleOnChange}
      error={!!error}
      helperText={error}
    />
  );
};
