import {
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { FC } from 'react';
import {
  Close as CloseIcon,
  CaseStudy as CaseStudyIcon,
  Lock as LockIcon,
} from '@mott-macdonald/smi-react-ui-kit/icons';
import { Link } from 'react-router-dom';
import { AppFullRoutePath, AppPage } from 'src/router';
import { TERMS_URL, PRIVACY_POLICY_URL } from 'src/configs';
import { setTermsAndConditionsProps } from 'src/store/termsAndConditions';
import { useAppDispatch } from 'src/store/useAppDispatch';
import useAuth from 'src/hooks/useAuth';
import { useUserInfo } from '../useUserInfo';

const XsUserMenu: FC<{
  // eslint-disable-next-line @rushstack/no-new-null
  anchorElement: HTMLElement | null;
  handleClose: () => void;
}> = ({ anchorElement, handleClose }) => {
  const { isAuthenticated } = useAuth();

  const userInfo = useUserInfo();

  const dispatch = useAppDispatch();

  const handleShowTerms = (): void => {
    dispatch(setTermsAndConditionsProps({ termsAndConditionsUrl: TERMS_URL }));
    handleClose();
  };

  const handleShowPrivacyPolicy = (): void => {
    dispatch(
      setTermsAndConditionsProps({
        termsAndConditionsUrl: PRIVACY_POLICY_URL,
        title: 'Digital solutions privacy notice',
      })
    );
    handleClose();
  };

  return (
    <Dialog
      open={!!anchorElement}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullScreen
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        id="dialog-title"
      >
        Account
        <IconButton edge="end" size="small" aria-label="info" onClick={handleClose} data-testid="close-helper-icon">
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </DialogTitle>
      <List disablePadding>
        <ListItem>
          <ListItemText
            primary={
              <Stack>
                <Typography color="text.secondary">{userInfo.displayName}</Typography>
                <Typography color="text.secondary">{userInfo.email}</Typography>
              </Stack>
            }
          />
        </ListItem>
        <ListItemButton
          data-testid="logout-link"
          onClick={handleClose}
          component={Link}
          to={AppFullRoutePath[AppPage.Logout]}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Log out" />
        </ListItemButton>
        <Divider />
        {isAuthenticated && (
          <>
            <ListItemButton data-testid="terms-link" onClick={handleShowTerms}>
              <ListItemIcon>
                <CaseStudyIcon />
              </ListItemIcon>
              <ListItemText primary="Terms & conditions" />
            </ListItemButton>
            <Divider />
          </>
        )}
        {isAuthenticated && (
          <>
            <ListItemButton data-testid="privacy-policy-link" onClick={handleShowPrivacyPolicy}>
              <ListItemIcon>
                <LockIcon />
              </ListItemIcon>
              <ListItemText primary="Privacy policy" />
            </ListItemButton>
            <Divider />
          </>
        )}
        <ListItem>
          <ListItemText primary={<>&copy; {new Date().getFullYear()} Mott MacDonald</>} />
        </ListItem>
      </List>
    </Dialog>
  );
};

export default XsUserMenu;
