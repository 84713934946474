import { FC } from 'react';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';
import { AppBar, Toolbar, Stack, ButtonBase } from '@mui/material';

import MoataLogo from 'src/assets/images/moata.svg?react';
import useGetScreenSize from 'src/hooks/useGetScreenSize';
import { AppFullRoutePath, AppPage } from 'src/router';
import HelperMenu from './HelperMenu';
import UserMenu from './UserMenu';
import StdHeaderLinks from './HeaderLinks/StdHeaderLinks';
import SmHeaderLinks from './HeaderLinks/SmHeaderLinks';
import { HEADER_HEIGHT } from './constants';

const Header: FC = () => {
  const { isMd } = useGetScreenSize('up');

  return (
    <>
      <AppBar sx={{ isolation: 'isolate' }}>
        <Toolbar
          disableGutters
          variant="dense"
          sx={{
            position: 'static',
            height: HEADER_HEIGHT,
            minHeight: HEADER_HEIGHT,
            display: 'grid',
            gridTemplateColumns: '1fr 25%',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Stack direction="row" justifyContent="flex-start" alignItems="center">
            <ButtonBase
              component={ReactRouterNavLink}
              sx={{
                height: HEADER_HEIGHT,
                // this number can make the "M" of the "MOATA" align with the single "M" on SubApp
                aspectRatio: '2.23/1',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'primary.main',
                flexShrink: 0,
              }}
              title="Home"
              to={AppFullRoutePath[AppPage.Home]}
              data-gtm-header-logo
            >
              <MoataLogo height="1rem" role="img" aria-label="Moata Logo" />
            </ButtonBase>
            {isMd && (
              <Stack
                direction="row"
                justifyContent="flex-start"
                justifySelf="flex-start"
                alignItems="center"
                alignSelf="stretch"
                sx={{ overflow: 'hidden' }}
              >
                <StdHeaderLinks />
              </Stack>
            )}
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            justifySelf="flex-end"
            alignItems="center"
            gap={1}
            px={2}
            sx={{ minWidth: 'fit-content' }}
          >
            <HelperMenu />
            <UserMenu />
          </Stack>
        </Toolbar>
      </AppBar>
      {!isMd && <SmHeaderLinks />}
    </>
  );
};

export default Header;
