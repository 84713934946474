import { FC } from 'react';
import { Help as HelpIcon, Chat as ChatIcon } from '@mott-macdonald/smi-react-ui-kit/icons';

interface HelperLink {
  label: string;
  link?: string;
  id: string;
  icon: FC;
}

export const HELPER_LINKS: Readonly<HelperLink[]> = [
  {
    label: 'Moata support',
    link: 'https://support.moata.com/hc/en-gb',
    id: 'support-link',
    icon: HelpIcon,
  },
  {
    label: 'Moata community',
    link: 'https://support.moata.com/hc/en-gb/community/topics',
    id: 'community-link',
    icon: ChatIcon,
  },
];
