import { FC, useState } from 'react';
import { IconButton } from '@mui/material';
import { QuestionMark } from '@mui/icons-material';
import useGetScreenSize from 'src/hooks/useGetScreenSize';
import XsHelperMenu from './XsHelperMenu';
import StdHelperMenu from './StdHelperMenu';

const HelperMenu: FC = () => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const { isXs } = useGetScreenSize();

  const handleClose = (): void => {
    setAnchorElement(null);
  };

  return (
    <>
      <IconButton
        size="small"
        onClick={(event) => setAnchorElement(event.currentTarget)}
        title="Help"
        sx={{ color: 'action.active' }}
      >
        <QuestionMark fontSize="small" />
      </IconButton>
      {isXs ? (
        <XsHelperMenu anchorElement={anchorElement} handleClose={handleClose} />
      ) : (
        <StdHelperMenu anchorElement={anchorElement} handleClose={handleClose} />
      )}
    </>
  );
};

export default HelperMenu;
