import { FC } from 'react';
import { Menu, MenuItem, Link } from '@mui/material';

import { HELPER_LINKS } from 'src/features/lobby/components/Header/HelperMenu/helperLinks';

const StdHelperMenu: FC<{
  // eslint-disable-next-line @rushstack/no-new-null
  anchorElement: HTMLElement | null;
  handleClose: () => void;
}> = ({ anchorElement, handleClose }) => {
  return (
    <Menu
      anchorEl={anchorElement}
      open={!!anchorElement}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      slotProps={{
        paper: { sx: { minWidth: '200px' } },
      }}
    >
      {HELPER_LINKS.map(({ label, link, id }) => (
        <MenuItem
          key={label}
          data-testid={id}
          onClick={handleClose}
          component={Link}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {label}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default StdHelperMenu;
