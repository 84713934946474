import { FC, PropsWithChildren } from 'react';
import { ProductAPI } from 'src/services/api/ProductAPI';
import { useAppSelector } from 'src/store/useAppSelector';
import { useCurrentProductId } from 'src/features/admin/hooks/useCurrentProductId';
import { AdminItemType } from 'src/features/admin/types';
import { GroupAdminRouteGuard } from '../GroupAdminRouteGuard/GroupAdminRouteGuard';

export const ProductGroupAdminRouteGuard: FC<PropsWithChildren> = ({ children }) => {
  const productId = useCurrentProductId();
  const { data, isLoading, isError } = ProductAPI.useGetById(Number(productId));

  const user = useAppSelector((state) => state.auth.user) ?? undefined;

  const isGroupAdmin = data?.productAdmins?.includes(user?.id ?? '') ?? false;

  return (
    <GroupAdminRouteGuard
      isGroupAdmin={isGroupAdmin}
      isLoading={isLoading}
      isError={isError}
      itemType={AdminItemType.Product}
    >
      {children}
    </GroupAdminRouteGuard>
  );
};
