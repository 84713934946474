import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { preloadImage } from '@mott-macdonald/smi-react-ui-kit';
import useContentQuery from 'src/services/api/useContentQuery';
import assertIsNotUndefined from 'src/utils/assertIsNotUndefined';

import getBanner from 'src/utils/getBanner';
import { getLocalCmsUrl } from 'src/utils/localCMS';
import BannerContent from './BannerContent';
import { BannerData } from './types';

interface Props {
  page?: 'home' | 'marketplace' | 'resources';
}

const HeaderBanner: FC<Props> = ({ page = 'home' }) => {
  const { data, isLoading, isError } = useContentQuery<BannerData[]>(getLocalCmsUrl('/banner/content.json'), {
    heading: { path: 'extended.properties[?(@.alias=="heading")].value' },
    subHeading: {
      path: 'extended.properties[?(@.alias=="subheading")].value',
    },
    image: {
      path: 'extended.properties[?(@.alias=="backgroundImageUrl")].value',
    },
    buttonLabel: {
      path: 'extended.properties[?(@.alias=="buttonLabel")].value',
    },
    buttonActionUrl: {
      path: 'extended.properties[?(@.alias=="buttonActionUrl")].value',
    },
    buttonActionUrlOpenNewTab: {
      path: 'extended.properties[?(@.alias=="buttonActionUrlOpenNewTab")].value',
    },
    page: {
      path: 'extended.properties[?(@.alias=="displayAlwaysPage")].value',
      optional: true,
    },
  });

  if (isLoading)
    return (
      <Box data-testid="banner-loading-image" component="img" height={260} src={preloadImage} alt="The banner image" />
    );

  if (isError)
    return (
      <Box
        data-testid="banner-error-image"
        component="div"
        height={260}
        sx={{
          overflow: 'hidden',
          height: 260,
          maxHeight: 260,
          backgroundImage: `url(${preloadImage})`,
          flex: 1,
          display: 'flex',
          backgroundSize: 'cover',
          backgroundPosition: 'right',
        }}
      >
        <Typography>Banner not loaded</Typography>
      </Box>
    );

  const bannersData = assertIsNotUndefined<BannerData[]>(data);
  const selectedBanner = getBanner(bannersData, page);

  const bannerData = assertIsNotUndefined<BannerData>(selectedBanner);
  return <BannerContent bannerData={bannerData} />;
};

export default HeaderBanner;
