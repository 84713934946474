import { FC, Fragment } from 'react';
import {
  IconButton,
  Link,
  Dialog,
  DialogTitle,
  List,
  ListItemText,
  ListItemButton,
  Divider,
  ListItemIcon,
} from '@mui/material';
import { ArrowRight as ArrowRightIcon, Close as CloseIcon } from '@mott-macdonald/smi-react-ui-kit/icons';
import { HELPER_LINKS } from 'src/features/lobby/components/Header/HelperMenu/helperLinks';

const XsHelperMenu: FC<{
  // eslint-disable-next-line @rushstack/no-new-null
  anchorElement: HTMLElement | null;
  handleClose: () => void;
}> = ({ anchorElement, handleClose }) => {
  return (
    <Dialog
      open={!!anchorElement}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullScreen
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        id="dialog-title"
      >
        Help
        <IconButton edge="end" size="small" aria-label="Close" onClick={handleClose} title="Close">
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </DialogTitle>
      <List disablePadding>
        {HELPER_LINKS.map(({ label, link, id, icon: Icon }) => (
          <Fragment key={id}>
            <ListItemButton onClick={handleClose} component={Link} href={link} data-testid={id} target="_blank">
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={label} />
              <ListItemIcon sx={{ minWidth: 'auto' }}>
                <ArrowRightIcon />
              </ListItemIcon>
            </ListItemButton>
            <Divider />
          </Fragment>
        ))}
      </List>
    </Dialog>
  );
};

export default XsHelperMenu;
