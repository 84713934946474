import { Link } from 'react-router-dom';
import { Divider, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { AppFullRoutePath, AppPage } from 'src/router';
import { TERMS_URL, PRIVACY_POLICY_URL } from 'src/configs';
import { setTermsAndConditionsProps } from 'src/store/termsAndConditions';
import { useAppDispatch } from 'src/store/useAppDispatch';
import useAuth from 'src/hooks/useAuth';
import { useUserInfo } from '../useUserInfo';

const StdUserMenu: FC<{
  // eslint-disable-next-line @rushstack/no-new-null
  anchorElement: HTMLElement | null;
  handleClose: () => void;
}> = ({ anchorElement, handleClose }) => {
  const { isAuthenticated } = useAuth();

  const userInfo = useUserInfo();

  const dispatch = useAppDispatch();

  const handleShowTerms = (): void => {
    dispatch(setTermsAndConditionsProps({ termsAndConditionsUrl: TERMS_URL }));
    handleClose();
  };

  const handleShowPrivacyPolicy = (): void => {
    dispatch(
      setTermsAndConditionsProps({
        termsAndConditionsUrl: PRIVACY_POLICY_URL,
        title: 'Digital solutions privacy notice',
      })
    );
    handleClose();
  };

  return (
    <Menu
      anchorEl={anchorElement}
      open={!!anchorElement}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      slotProps={{
        paper: { sx: { minWidth: '180px' } },
      }}
    >
      <Stack sx={{ px: 2, py: 0.75 }}>
        <Typography color="text.secondary" variant="caption">
          {userInfo.displayName}
        </Typography>
        <Typography color="text.secondary" variant="caption">
          {userInfo.email}
        </Typography>
      </Stack>
      <MenuItem data-testid="logout" component={Link} to={AppFullRoutePath[AppPage.Logout]}>
        Log out
      </MenuItem>
      <Divider />
      {isAuthenticated && (
        <MenuItem data-testid="terms-link" onClick={handleShowTerms}>
          Terms & conditions
        </MenuItem>
      )}
      {isAuthenticated && (
        <MenuItem data-testid="privacy-policy-link" onClick={handleShowPrivacyPolicy}>
          Privacy policy
        </MenuItem>
      )}
      <Stack sx={{ px: 2, py: 0.75 }}>
        <Typography color="text.secondary" variant="caption">
          &copy; {new Date().getFullYear()} Mott MacDonald
        </Typography>
      </Stack>
    </Menu>
  );
};

export default StdUserMenu;
