import { FC } from 'react';
import { Container, Stack, Typography, Button, Link, Box } from '@mui/material';
import { useCloudinaryImage } from '@mott-macdonald/smi-react-ui-kit';

import { BannerData } from '../types';

interface Props {
  bannerData: BannerData;
}

const BannerContent: FC<Props> = ({ bannerData }: Props) => {
  const { heading, subHeading, image, buttonLabel, buttonActionUrl, buttonActionUrlOpenNewTab } = bannerData;

  const { imageRef, transformedImageUrl } = useCloudinaryImage<HTMLImageElement>({ imageUrl: image });

  return (
    <Container
      maxWidth={false}
      data-testid="header-banner-image"
      sx={{
        overflow: 'hidden',
        height: 260,
        minHeight: 260,
        maxHeight: 260,
        backgroundImage: `url(${transformedImageUrl})`,
        flex: 1,
        display: 'flex',
        backgroundSize: 'cover',
        backgroundPosition: 'right',
      }}
      disableGutters
      ref={imageRef}
    >
      <Stack
        data-testid="header-banner-gradient-layer"
        justifyContent="center"
        alignItems="baseline"
        spacing={2}
        px={6}
        py={6}
        sx={{
          maxWidth: 720,
          width: '100%',
          background: 'linear-gradient(90.58deg, #000000 0.6%, rgba(0, 0, 0, 0) 97.83%)',
        }}
      >
        <Box sx={{ maxWidth: 400 }}>
          <Typography
            gutterBottom
            variant="h3"
            color="white"
            sx={{
              maxWidth: 400,
              overflow: 'hidden',
              wordWrap: 'break-word',
              margin: 0,
            }}
          >
            {heading}
          </Typography>
          <Typography
            gutterBottom
            color="white"
            fontSize="medium"
            sx={{ maxWidth: 400, overflow: 'hidden', wordWrap: 'break-word' }}
          >
            {subHeading}
          </Typography>
        </Box>
        {buttonLabel !== '' && buttonActionUrl !== '' && (
          <Button
            component={Link}
            size="large"
            variant="contained"
            href={buttonActionUrl}
            target={Number(buttonActionUrlOpenNewTab) ? '_blank' : '_self'}
            rel="noopener"
          >
            {buttonLabel}
          </Button>
        )}
      </Stack>
    </Container>
  );
};

export default BannerContent;
